@import '../../../public/css/color.scss';

.about-left-content {
  display: none;
}

.about-main {
  position: relative;
  max-width: 100% !important;
}

.bg-title {
  position: absolute;
  font-size: 7rem;
  //   text-align: center;
  opacity: 0.1;
  top: 2%;
  left: 30%;
  letter-spacing: 15px;
}

.bg-title,
.about-head-title {
  font-family: Poppins, sans-serif;
  font-weight: 900;
}
.about-main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $primary;
  color: white;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 3%;
  scroll-behavior: smooth;
}

.about-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.about-main::-webkit-scrollbar {
  width: 10px;
}

.about-main::-webkit-scrollbar-thumb {
  background: #888;
}

.about-head-title {
  font-size: 3.6rem;
  text-align: center;
  padding-top: 4%;
}

.about-me {
  color: $secondary;
}

.personal-infos,
.about-top,
.experiences {
  display: grid;
  grid-template-columns: 50% 50%;
}

.personal-infos {
  border-bottom: 1px solid #b4b4b41e;
  padding-top: 2%;
  padding-bottom: 4%;
}

.about-top {
  padding-top: 7%;
  padding-left: 10%;
  padding-right: 10%;
}

.personal-title {
  font-weight: 900;
  font-size: 1.9rem;
}

.info-value {
  font-weight: 600;
}

.single-personal-info,
.personal-title {
  margin-bottom: 6%;
}

.download-cv {
  border: 1px solid $secondary;
  color: white;
  padding: 10px 35px;
  border-radius: 40px;
  width: 50%;
  font-family: Poppins, sans-serif;
  margin-bottom: 10%;
  margin-top: 10%;
}

.download-cv::after {
  background-color: $secondary;
  content: '';
  position: absolute;
  left: 30%;
  top: 98%;
  height: 2px;
  width: 70px;
  border-radius: 10px;
  background-size: cover;
}

.about-social {
  //   position: absolute;
  width: 100%;
  margin-top: -16%;
  margin-left: 62%;
}

.download-cv-icon {
  display: inline-block;
  margin-left: 10%;
}

.experience-number {
  color: $secondary;
  font-size: 3rem;
  font-weight: 900;
}

.single-experience {
  border: 1px solid #ffffff23;
  margin-bottom: 6px;
  padding: 5%;
  margin-left: 6%;
  padding-bottom: 8%;
}

.experience-sign {
  font-weight: 400;
  font-size: 2rem;
  margin-top: -10% !important;
  vertical-align: baseline;
}

.experience-word {
  width: 64%;
  margin-left: 30%;
}

@media (max-width: 540px) {
  .about-left-content {
    display: block;
  }

  .about-main {
    padding-bottom: 20%;
  }
  .bg-title {
    display: none;
  }

  .about-head-title {
    font-size: 2.2rem;
    text-align: left;
    padding-left: 5%;
  }
  .personal-title {
    font-weight: 700;
    font-size: 1.3rem;
    text-align: left;
    margin-left: 3%;
  }

  .about-top {
    grid-template-columns: 100%;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .personal-infos {
    width: 97%;
    margin: auto;
    margin-top: 10%;
  }

  .info-value {
    display: block;
    color: white;
  }

  .single-personal-info {
    margin-bottom: 5%;
    color: $primary-white;
  }

  .download-cv {
    width: 90%;
    margin: auto;
    margin-bottom: 20%;
    margin-top: 10%;
    text-align: center;
  }

  .about-social {
    width: 100%;
    margin-top: -16%;
    margin-left: 24%;
    margin-bottom: 40%;
  }

  .experience-number {
    font-size: 2.6rem;
  }

  .single-experience {
    border: 1px solid #ffffff23;
    border-radius: 10px;
    margin-bottom: 30%;
  }

  .experience-sign {
    font-weight: 400;
    font-size: 1.7rem;
    margin-top: -10% !important;
    vertical-align: baseline;
  }

  .experience-word {
    width: 64%;
    margin-left: 30%;
    font-size: 1rem;
  }
  .download-cv::after {
    display: none;
  }
}
