@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sp-widget-launcher {
  z-index: 5000;
}
.sp-widget-launcher-close,
.sp-widget-launcher-logo {
  width: 25px;
  height: 25px;
}

.sp-widget-launcher.sp-is-launcher-type-superpeer-logo {
  width: 60px;
  height: 60px;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 767px) {
  .sp-widget.sp-is-embed-type-slide-in.sp-is-launcher-type-superpeer-logo {
    bottom: 120px;
    // max-height: calc(100% - 92px);
  }
  .sp-widget-launcher {
    bottom: 62px;
  }
}

@media (max-width: 540px) {
  .sp-widget-launcher.sp-is-launcher-type-superpeer-logo,
  .sp-widget-launcher,
  .sp-widget-launcher-close {
    margin-top: -25% !important;
  }
}
