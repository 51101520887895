@import '../../../public/css/color.scss';

.side-bar {
  z-index: 3500 !important;
  position: fixed;
  right: 2%;
  top: 25%;
}

.sidebar-icon {
  font-size: 1.3rem;
  color: $primary-white;
  background-color: $primary-second;
  z-index: 3500;
  margin-bottom: 60%;
  padding: 13px;
  border-radius: 50%;
  cursor: pointer;
}

.sidebar-icon-active {
  transition: 0.5s ease-in-out;
  color: white;
  background-color: $secondary;
  font-size: 1.3rem;
  z-index: 3500;
  margin-bottom: 60%;
  padding: 13px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 540px) {
  .side-bar {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    left: 0;
    // right: inherit;
    right: 0;
    // grid-gap: 8%;
    background-color: $primary-nav;
    padding: 3%;
    justify-content: space-around;
    top: 90%;
    z-index: 6600;
  }

  .sidebar-icon,
  .sidebar-icon-active {
    font-size: 1.3rem;
    margin-bottom: 0%;
    // left: 14%;
    left: inherit;
    // margin-left: 10%;
    width: 65%;
    height: 95%;
  }
}
