@import '../../../public/css/color.scss';

.developer-name::before {
  background-color: $secondary;
  content: '';
  position: absolute;
  left: 42%;
  top: 34%;
  height: 4px;
  width: 40px;
  border-radius: 10px;
  background-size: cover;
}

.orange-bg {
  background: rgb(255, 180, 0);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  z-index: -2;
  background-size: cover;
}

.black-bg {
  background: #111;
  position: absolute;
  height: 100vh;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 40% 60%;
  clip-path: polygon(9% 0, 100% 0, 100% 100%, 26% 100%);
  z-index: -1;
  background-size: cover;
}

.main {
  // position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 40% 60%;
  z-index: 3800;
  overflow: hidden;
  height: 100%;
}

.btn-more {
  border: 1px solid $secondary;
  color: white;
  border-radius: 40px;
  width: 41%;
  font-family: Poppins, sans-serif;
}

.btn-more-padding {
  padding: 10px 35px;
}

.btn-more-icon {
  display: inline-block;
  margin-left: 4%;
  // background-color: $secondary;

  margin-bottom: 0 !important;
  margin: 0 !important;
  padding: 0;
  // padding: 4%;
}

.developer-name {
  color: $secondary;
  font-size: 2.8em;
  margin-bottom: -2%;
}

.developer-title,
.developer-desc {
  padding-bottom: 2%;
}

.developer-title,
.developer-desc {
  color: white;
}

.developer-name,
.developer-title {
  font-weight: 900;
  font-family: Poppins, sans-serif;
}

.developer-desc {
  line-height: 2.1;
  font-family: Poppins, sans-serif;
}

.developer-title {
  font-size: 2.3em;
}

.right-content {
  width: 80%;
  padding-top: 25%;
  padding-left: 10%;

  &h1 {
    padding-bottom: 5%;
  }

  &p {
    margin-bottom: 10%;
  }
}

.left-content {
  position: relative;
  background-color: $primary-second;
  box-shadow: 0 0 7px rgb(0 0 0 / 90%);
  height: 83vh;
  margin-top: 10%;
  width: 68%;
  margin-left: 15%;
  background-image: url(../../assets/img/olumide.jpg);
  background-size: cover;
  background-position: bottom;
  border-radius: 20px;
}

.left-content-img {
  background-position: center;
}

.footer-date {
  color: $primary-white;
  // position: relative;
  margin-top: 2%;
  margin-left: 40%;
  font-size: 14px;
  font-family: Poppins, sans-serif;
}

@media (max-width: 912px) {
  .main {
    display: grid;
    grid-template-columns: 100%;
    z-index: 3800;
    overflow: block;
    overflow-y: auto;
    height: 100vh;
    width: 95% !important;
    margin: auto;
    padding-bottom: 30%;
    // background-color: red;
  }

  .black-bg {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }

  .left-content {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    border: 4px solid $primary-second;
    margin-left: 23%;
  }

  .developer-name {
    font-size: 1.5em;
    text-align: center;
  }
  .developer-title {
    font-size: 1.4em;
    text-align: center;
  }

  .right-content {
    width: 80%;
    margin: auto;
    padding-top: 5%;
    padding-left: 0%;
    text-align: center;
  }

  .developer-name::before {
    display: none;
  }

  .developer-desc {
    line-height: 1.6;
    font-family: Poppins, sans-serif;
    font-size: 0.9rem;
    max-width: 98% !important;
    margin: auto;
    // text-align: justify;
  }

  .btn-more {
    width: 45%;
    margin: auto;
    margin-top: 5%;
  }

  .footer-date {
    margin-top: 13%;
    margin-left: 0%;
    margin-bottom: 20%;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .main {
    display: grid;
    grid-template-columns: 100%;
    z-index: 3800;
    overflow: block;
    overflow-y: auto;
    height: 100vh;
    width: 95% !important;
    margin: auto;
    padding-bottom: 30%;
    // background-color: red;
  }

  .black-bg {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }

  .left-content {
    border-radius: 50%;
    height: 190px;
    width: 190px;
    border: 4px solid $primary-second;
    margin-left: 23%;
  }

  .developer-name {
    font-size: 1.5em;
    text-align: center;
  }
  .developer-title {
    font-size: 1.4em;
    text-align: center;
  }

  .right-content {
    width: 80%;
    margin: auto;
    padding-top: 5%;
    padding-left: 0%;
    text-align: center;
  }

  .developer-name::before {
    display: none;
  }

  .developer-desc {
    line-height: 1.6;
    font-family: Poppins, sans-serif;
    font-size: 0.9rem;
    max-width: 98% !important;
    margin: auto;
    // text-align: justify;
  }

  .btn-more {
    width: 90%;
    margin: auto;
    margin-top: 10%;
  }

  .footer-date {
    margin-top: 13%;
    margin-left: 0%;
    margin-bottom: 20%;
    text-align: center;
  }
}
