@import '../../../public/css/color.scss';

.portfolio {
  background-color: $primary;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: white;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 3%;
  scroll-behavior: smooth;
}

.portfolio-head-title {
  font-size: 3.6rem;
  text-align: center;
  padding-top: 3.3%;
  font-family: Poppins, sans-serif;
  font-weight: 900;
}

.portfolio::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.portfolio::-webkit-scrollbar {
  width: 10px;
}

.portfolio::-webkit-scrollbar-thumb {
  background: #888;
}

.portfolio-project {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3%;
  margin: 7%;
  padding-bottom: 7%;
  border-bottom: 1px solid #ffffff2f;
}

.portfolio-web {
  margin-bottom: 3%;
  position: relative;
}

.portfolio-web-img img {
  border-radius: 2%;
  z-index: 1;
}

.project-detail {
  display: none;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: $primary-second;
  text-align: center;
  padding-top: 10%;
  z-index: 2;
}

.portfolio-web-img:hover + .project-detail,
.project-detail:hover {
  display: block;
}

.project-detail::before {
  content: '';
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  top: 0;
  left: 0;
}

.project-name-title {
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 7%;
}

.project-url {
  border: 1px solid $secondary;
  border-radius: 50px;
  padding: 10px 25px;
}

.project-url:hover {
  background-color: $secondary;
  color: $primary;
  transition: background ease-in-out 0.2s;
}

.developer-package {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3%;
  margin: 6%;
}

.developer-package-title {
  font-family: Poppins, sans-serif;
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 2%;
}

.developer-package-img img {
  border-radius: 10px;
  border-color: $primary;
}

.developer-package-img {
  border-color: $primary;
  width: 80%;
}

.developer-package-desc {
  font-family: Poppins, sans-serif;
  padding-top: 10%;
  width: 80%;
  margin: auto;
  line-height: 1.9;
}

.project-url {
  font-size: 0.9rem;
}

@media (max-width: 540px) {
  .portfolio-head-title {
    font-size: 2.2rem;
    text-align: left;
    padding-left: 5%;
  }
  .portfolio-project {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0%;
  }

  .developer-package {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0%;
  }

  .developer-package-title {
    line-height: 1.2;
  }

  .developer-package-desc {
    font-family: Poppins, sans-serif;
    padding-top: 10%;
    width: 95%;
    margin: auto;
    line-height: 1.5;
    margin-bottom: 5%;
  }

  .developer-package-img {
    border-color: $primary;
    width: 80%;
    margin: auto;
  }

  .developer-p {
    display: block;
  }

  .project-name-title {
    font-size: 1.5rem;
    margin-bottom: 7%;
  }
}
